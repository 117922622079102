body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Isidora Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Isidora Sans"), local("Isidora-Sans"),
    url(https://fanstories-res.s3.amazonaws.com/fonts/IsidoraSans-Regular.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Isidora Sans Black";
  font-style: normal;
  font-weight: 400;
  src: local("Isidora Sans"), local("Isidora-Sans"),
    url(https://fanstories-res.s3.amazonaws.com/fonts/IsidoraSans-Black.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Isidora Sans Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Isidora Sans"), local("Isidora-Sans"),
    url(https://fanstories-res.s3.amazonaws.com/fonts/IsidoraSans-Bold.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Isidora Sans SemiBold";
  font-style: normal;
  font-weight: 400;
  src: local("Isidora Sans"), local("Isidora-Sans"),
    url(https://fanstories-res.s3.amazonaws.com/fonts/IsidoraSans-SemiBold.ttf)
      format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
